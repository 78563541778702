<template>
  <div class="subourservice">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>我们的服务</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/invoicing" title="开票服务">开票服务</a>
        <a href="/baseHome/valueAdded" title="增值服务">增值服务</a>
        <a href="/baseHome/ourservice" title="我们的服务" class="on">我们的服务</a>
      </div>
    </div>
    <div class="sub-title">我们的服务</div>
    <div class="wrapper">
      <div class="ourtext">山水物联平台团队核心成员拥有10年以上的物流系统平台搭建与软件工程相关从业经验，为诸多知名物流企业的整体信息化平台建设提供规划咨询与系统研发实施服务。山水物联专业的研发与实施团队，对客户业务的深入洞察，基于成熟的产品线与云解决方案，帮助客户快速构建一站式信息化平台。本着与客户共成长为核心理念，站在用户立场，用产品与服务让用户获得更好的体验与价值，获得快速的投资回报。</div>

      <div class="ourcircle">
        <img src="../../assets/images/our1.png">
        <div class="wave1 wave2" style=" top:130px; margin-left:-230px; ">
          <div class="text1">专业的服务人员</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>
        <div class="wave1 wave2" style=" top:300px; margin-left:-300px; ">
          <div class="text1">业务场景深入洞察</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>
        <div class="wave1 wave2" style=" top:470px; margin-left:-260px; ">
          <div class="text1">高效率协作团队</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>

        <div class="wave1 wave2" style=" top:130px; margin-left:198px; ">
          <div class="text1" style="text-align:left; left: 50px;">高效率协作团队</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>

        <div class="wave1 wave2" style=" top:300px; margin-left:270px; ">
          <div class="text1" style="text-align:left; left: 50px;">多年行业实践沉淀</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>

        <div class="wave1 wave2" style=" top:470px; margin-left:225px; ">
          <div class="text1" style="text-align:left; left: 50px;">持续更新的云解决方案</div>
          <div class="w0" />
          <div class="w1" />
          <div class="w2" />
          <div class="w3" />
          <div class="w4" />
        </div>

      </div>

      <div class="sub-title" style=" padding-top:10px;">服务内容</div>
      <div class="ourservice">
        <dl>
          <dt><h3>01</h3>咨询服务</dt>
          <dd>基于信息化角度对客户业务发展和未来战略进行专业分析，业务流程梳理和优化、系统蓝图规划、系统上线实施策略等。</dd>
        </dl>
        <dl>
          <dt><h3>02</h3>方案设计</dt>
          <dd>我司咨询顾问在物流信息化和供应链相关业务领域有着近20年丰富的实践经验,能够深入了解用户需求，挖掘企业现存运营及管理难点、痛点，
            针对性提供整套的信息化建设方案包括系统架构和功能规划、系统集成方案、项目实施规划等。</dd>
        </dl>
        <dl>
          <dt><h3>03</h3>实施服务</dt>
          <dd>我司的实施服务通过科学的实施方法、严格的质量保障体系、快速的实施步骤，达成项目成功落地，并帮助企业实现产品应用价值的最大化。</dd>
        </dl>
        <dl>
          <dt><h3>04</h3>集成服务</dt>
          <dd>我们与众多知名的软硬件企业紧密合作，为企业提供智能化的全渠道部署，同时为客户提供系统方案设计、网络系统设计、数据采集设备方案设计等综合的系统集成方案。</dd>
        </dl>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subourservice {
  .bg{background:url(./../../assets/images/serbg.jpg) }
  .ourtext{ line-height: 39px; color: #222; font-size: 16px;}
  .ourcircle{ position:relative; text-align: center;
    .wave2{position: absolute; left: 50%; top:0;width: 35px; height: 35px;
      .w0{background: #2e7ee1; width: 11px; height: 11px; border-radius: 50%; left: 12px; top: 12px; position: absolute;}
      .w1,.w2,.w3,.w4{background: #2e7ee1;}
      .text1{ position: absolute; right: 50px; top: 5px; font-size: 24px; color: #0062db; width: 500px; text-align: right;}
    }
    .title1{ position: absolute; left: 0; right: 0; font-size: 24px; color: #0062db;}
  }
  .ourservice{ overflow: hidden; padding-bottom: 60px;
    dl{padding: 0 0 50px 0;
      dt{ color: #333; font-size: 22px; line-height: 34px;
        h3{ color: #fff; font-weight: normal; width: 34px; height: 34px; text-align: center; background: #2e7ee1; display: inline-block; float: left; margin: 0 20px 0 0;}
      }
      dd{padding:17px 0 0 0; font-size: 16px; line-height: 30px; color: #666;}
    }
    dl dt img{width: 305px; height: 226px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    dl:hover dt img{width: 330px; height: 245px; margin-top: -10px; margin-left: -12px;}
    dl:nth-child(even){margin-right: 0;}
  }

}
</style>
